@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@400;600;800&display=swap");

body {
  margin: 0;
  font-family: "Darker Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
@font-face {
  font-family: 'Maragasa';
  src: local('Maragasa'), url(./fonts/Maragsƒ.otf) format('opentype');
}
@font-face {
  font-family: 'Mont';
  src: local('Mont'), url(./fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}
@font-face {
  font-family: 'Source';
  src: local('Source'), url(./fonts/SourceSansPro-Regular.ttf) format('truetype');
}